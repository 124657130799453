export const Loader = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "110%",
        zIndex: 1505,
        position: "absolute",
        top: 0,
        left: 0,
        backgroundColor: "rgba(0,0,0,0.3)",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        className={"loader"}
        src={require("../../assets/img/logo-02.png")}
        alt='logo'
        style={{ width: 50, height: 50 }}
      />
      <div
        style={{
          fontSize: 15,
          fontFamily: "Avenir",
          color: "#000",
          marginTop: 20,
        }}
      >
        Intelligence Loading
      </div>
    </div>
  );
};
