import React, { useState, useEffect } from "react";
import { List, ListItemButton, ListItemIcon, ListItemText, Drawer, IconButton } from "@mui/material";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import StorefrontIcon from "@mui/icons-material/Storefront";
import { useNavigate } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import type { RootState } from "../../store";

const drawerWidth = 270;

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24,
  },
  toolbarIcon: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "0 8px",
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    fontSize: 24,
    fontFamily: "Poppins",
    color: "#263238",
    marginRight: 35,
    textTransform: "capitalize",
  },
  periodFilter: {
    marginRight: 20,
  },
  select: {
    paddingRight: "10px!important",
    position: "initial",
  },
  selectItem: {
    fontSize: 13,
    fontFamily: "Poppins-Medium",
    color: "#263238",
    "&:hover": {
      color: "#3966D0",
    },
  },
  menuItem: {
    fontSize: 13,
    fontFamily: "Poppins-Medium",
    color: "#263238",
    "&:hover": {
      backgroundColor: "rgba(96,132,217, 0.14)!important",
    },
  },
  label: {
    fontSize: 14,
    fontFamily: "Poppins",
    color: "#263238",
    fontWeight: 500,
    lineHeight: 20,
  },
  checkbox: {
    padding: 0,
    marginRight: 3,
  },
  drawerPaper: {
    whiteSpace: "nowrap",
    width: drawerWidth,
    [theme.breakpoints.down("md")]: {
      width: "142px",
    },
  },
  drawerContainer: {
    "& :first-child": {
      position: "relative !important",
    },
  },
  logo: {
    width: 150,
    marginLeft: 20,
    [theme.breakpoints.down("md")]: {
      width: 90,
      marginLeft: -5,
    },
  },
  buttonListItem: {
    [theme.breakpoints.down("md")]: {
      paddingLeft: 20,
    },
  },
  iconListItem: {
    [theme.breakpoints.down("md")]: {
      minWidth: 32,
    },
  },
  drawerPaperClose: {
    overflowX: "hidden",
    display: "none !important",
  },
  content: {
    flexGrow: 1,
    width: "100%",
    height: "100vh",
    overflow: "auto",
    backgroundColor: "#fafafa",
  },
  container: {
    marginTop: 24,
    marginBottom: 24,
  },
  paper: {
    padding: 16,
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  LogoutPaper: {
    padding: 16,
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    backgroundColor: "white",
  },
  fixedHeight: {
    height: 240,
  },
  margin: {
    margin: 24,
  },
}));

interface Props {
  open: boolean;
  setOpen: Function;
}

export const Sidemenu = ({ open, setOpen }: Props): JSX.Element => {
  const classes = useStyles();
  const navigate = useNavigate();
  const Ads = useSelector((state: RootState) => state.adLibrarySlice.ads);
  const [cartCounter, setCartCounter] = useState(0);

  useEffect(() => {
    setCartCounter(Ads.length);
  }, [Ads]);

  return (
    <>
      <Drawer
        variant='permanent'
        classes={{
          paper: `${classes.drawerPaper} ${!open && classes.drawerPaperClose}`,
        }}
        className={classes.drawerContainer}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton
            onClick={() => {
              setOpen(false);
            }}
          >
            <svg width='14' height='12' viewBox='0 0 14 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path d='M0 0H14V2H0V0ZM0 5H14V7H0V5ZM0 10H14V12H0V10Z' fill='#263238' />
            </svg>
          </IconButton>
          <span>
            <img src={require("../../assets/img/logo-01.png")} alt='logo' className={classes.logo} />
          </span>
        </div>
        <List sx={{ height: "100vh" }}>
          <Link
            to={"/ad-library"}
            key={"adLibrary"}
            style={{
              textDecoration: "none",
              color: "black",
            }}
          >
            <ListItemButton className={classes.buttonListItem} sx={{ pl: 4 }}>
              <ListItemIcon className={classes.iconListItem}>
                <ShoppingBasketIcon />
              </ListItemIcon>
              <ListItemText primary={"Ad Library"} />
            </ListItemButton>
          </Link>
          <Link
            to={"/cart"}
            key={"cart"}
            style={{
              textDecoration: "none",
              color: "black",
            }}
          >
            <ListItemButton className={classes.buttonListItem} sx={{ pl: 4 }}>
              <ListItemIcon className={classes.iconListItem}>
                <ShoppingCartIcon />
              </ListItemIcon>
              <ListItemText primary={"Cart"} />
              <div
                style={{
                  position: "absolute",
                  left: 150,
                  backgroundColor: "#991700",
                  color: "#fff",
                  fontSize: 12,
                  fontFamily: "Poppins",
                  width: 20,
                  height: 20,
                  borderRadius: 10,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {cartCounter}
              </div>
            </ListItemButton>
          </Link>
          <Link
            to={"/my-account"}
            key={"myAccount"}
            style={{
              textDecoration: "none",
              color: "black",
            }}
          >
            <ListItemButton className={classes.buttonListItem} sx={{ pl: 4 }}>
              <ListItemIcon className={classes.iconListItem}>
                <AccountCircleIcon />
              </ListItemIcon>
              <ListItemText primary={"My Account"} />
            </ListItemButton>
          </Link>
          <Link
            to={"/my-ads"}
            key={"myAds"}
            style={{
              textDecoration: "none",
              color: "black",
            }}
          >
            <ListItemButton className={classes.buttonListItem} sx={{ pl: 4 }}>
              <ListItemIcon className={classes.iconListItem}>
                <StorefrontIcon />
              </ListItemIcon>
              <ListItemText primary={"My Ads"} />
            </ListItemButton>
          </Link>
        </List>
      </Drawer>
    </>
  );
};
