import React, { useEffect, useState, useRef } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import TopBar from "../TopBar";
import SideMenu from "../Sidemenu";
import { UserData } from "../../model/user/user";
import api from "../../services/config";
import API from "../../services/api_data";
import { useDispatch, useSelector } from "react-redux";
import { setUserData } from "../../reducers/user";
import { RootState } from "../../store";
import Loader from "../loader";
import Swal from "sweetalert2";

const Login = React.lazy(() => import("../../screens/Login"));
const ResetPassword = React.lazy(() => import("../../screens/ResetPassword"));
const ChangePassword = React.lazy(() => import("../../screens/ChangePassword"));
const Signup = React.lazy(() => import("../../screens/Signup"));
const Cart = React.lazy(() => import("../../screens/Cart"));
const AdLibrary = React.lazy(() => import("../../screens/AdLibrary"));
const MyAccount = React.lazy(() => import("../../screens/MyAccount"));
const MyAds = React.lazy(() => import("../../screens/MyAds"));
const PaymentAccepted = React.lazy(() => import("../../screens/paymentAccepted"));
const PaymentDeclined = React.lazy(() => import("../../screens/paymentDeclined"));
const PaymentCancelled = React.lazy(() => import("../../screens/paymentCancelled"));

const isFromOutside = (routeName: string) => {
  if (routeName === "login" || routeName === "reset-password" || routeName === "signup") {
    return true;
  }
  return false;
};

export const Layout = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(true);
  const Ads = useSelector((state: RootState) => state.adLibrarySlice.ads);
  const userData = useSelector((state: RootState) => state.userSlice.user);
  const prevLocationRef = useRef(location);
  const [renderAuth, setRenderAuth] = useState(false);
  const [isTourRunning, setIsTourRunning] = useState(false);

  useEffect(() => {
    const User: string | null = localStorage.getItem("auth");
    let isExpired = false;
    if (User) {
      const UserData: UserData = JSON.parse(User);
      isExpired = api.oauth.verifyIfTokenExpired(UserData.token);
    }
    if (!localStorage.getItem("auth") || isExpired) {
      setRenderAuth(false);
      if (window.location.pathname.includes("change-password") || window.location.pathname.includes("signup")) {
        navigate(`/${location.pathname.substring(1)}`);
      } else {
        navigate("/login");
      }
    } else {
      const User: string | null = localStorage.getItem("auth");
      if (User) {
        const UserData: UserData = JSON.parse(User);
        setRenderAuth(true);
        dispatch(setUserData(UserData));
        api.oauth.updateAuth(UserData);
        if (location.pathname.substring(1)) {
          if (isFromOutside(location.pathname.substring(1))) {
            navigate("/ad-library");
          } else {
            navigate(`/${location.pathname.substring(1)}`);
          }
        } else {
          navigate("/ad-library");
        }
      }
    }
  }, [localStorage.getItem("auth")]);

  useEffect(() => {
    // check activity every 3 mins
    const interval = setInterval(() => {
      const User: string | null = localStorage.getItem("auth");
      if (User) {
        const UserData: UserData = JSON.parse(User);
        const isExpired = api.oauth.verifyIfTokenExpired(UserData.token);
        if (isExpired) {
          onRefreshToken(UserData);
        }
      }
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  const onRefreshToken = (UserData: UserData) => {
    const refreshObject = {
      userId: UserData.user.userId,
      refreshToken: UserData.user.refreshToken,
    };
    API.refreshToken(refreshObject)
      .then((response) => {
        if (response && response.accessToken && response.refreshToken) {
          const token = api.oauth.processNewToken({
            issued_at: null,
            expires_at: null,
            access_token: response.accessToken,
          });
          const user = {
            ...UserData.user,
            accessToken: response.accessToken,
            refreshToken: response.refreshToken,
          };
          const auth = {
            user: user,
            token: token,
          };
          dispatch(setUserData(auth));
          api.oauth.updateAuth(auth);
        } else {
          api.oauth.logout();
          setRenderAuth(false);
          navigate("/login");
        }
      })
      .catch((error) => {
        if (error) {
          Swal.fire("Error", "The token has expired, you will need to logged in again", "error");
          api.oauth.logout();
          setRenderAuth(false);
          navigate("/login");
        }
      });
  };

  const saveCart = () => {
    if (Ads.length) {
      const data = {
        userId: userData.userId,
        adsJsonObject: JSON.stringify(Ads),
      };
      API.saveCartAds(data)
        .then((response) => {})
        .catch((error) => {});
    }
  };

  useEffect(() => {
    if (prevLocationRef.current.pathname === "/ad-library") {
      saveCart();
    }
    prevLocationRef.current = location;
  }, [location]);

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      if (Ads.length) {
        const data = {
          userId: userData.userId,
          adsJsonObject: JSON.stringify(Ads),
        };
        API.saveCartAds(data)
          .then((response) => {})
          .catch((error) => {});
      }
    };

    // Add the event listener when the component mounts
    if (window.addEventListener) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    }

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [Ads, dispatch]);

  const handleTourStart = () => {
    setIsTourRunning(true);
  };

  const handleTourEnd = (tourState: any) => {
    // Check if the tour was completed or if it was closed by the user
    if (tourState.action === "close" || tourState.status === "finished") {
      setIsTourRunning(false);
    }
  };
  return (
    <>
      {renderAuth && (
        <TopBar setRenderAuth={setRenderAuth} handleTourStart={handleTourStart} open={open} setOpen={setOpen} />
      )}
      <div style={{ display: "flex" }}>
        {renderAuth && <SideMenu open={open} setOpen={setOpen} />}
        <main style={{ flex: 1 }}>
          {renderAuth && <div style={{ minHeight: "64px" }} />}
          <React.Suspense fallback={<Loader />}>
            <Routes>
              <Route path='/login' element={<Login setRenderAuth={setRenderAuth} />}></Route>
              <Route path='/reset-password' element={<ResetPassword />}></Route>
              <Route path='/change-password/:id' element={<ChangePassword />}></Route>
              <Route path='/signup' element={<Signup />}></Route>
              <Route
                path='/cart'
                element={<Cart open={open} isTourRunning={isTourRunning} handleTourEnd={handleTourEnd} />}
              ></Route>
              <Route
                path='/my-account'
                element={<MyAccount open={open} isTourRunning={isTourRunning} handleTourEnd={handleTourEnd} />}
              ></Route>
              <Route
                path='/ad-library'
                element={<AdLibrary open={open} isTourRunning={isTourRunning} handleTourEnd={handleTourEnd} />}
              ></Route>
              <Route
                path='/my-ads'
                element={<MyAds open={open} isTourRunning={isTourRunning} handleTourEnd={handleTourEnd} />}
              ></Route>
              <Route path='/payment-accepted' element={<PaymentAccepted open={open} />}></Route>
              <Route path='/payment-declined' element={<PaymentDeclined open={open} />}></Route>
              <Route path='/payment-cancelled' element={<PaymentCancelled open={open} />}></Route>
            </Routes>
          </React.Suspense>
        </main>
      </div>
    </>
  );
};
