import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Modal, Button, Box, Backdrop } from "@mui/material";
import { DateRangePicker } from "react-date-range";
import styled from "styled-components";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useDispatch, useSelector } from "react-redux";
import { setDates } from "../../reducers/filters";
import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  addYears,
  startOfYear,
  endOfYear,
  startOfWeek,
  endOfWeek,
  isSameDay,
} from "date-fns";

const ModalBox = styled(Box)`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  background-color: #ffffff;
  box-shadow: 4px 4px 20px rgba(57, 71, 165, 0.22);
`;

function CalendarModal() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const startDate = useSelector((state) => state.filtersSlice.startDate);
  const endDate = useSelector((state) => state.filtersSlice.endDate);
  const openCalendar = useSelector((state) => state.filtersSlice.openCalendar);
  const [dateRange, setDateRange] = React.useState([
    {
      startDate: startDate,
      endDate: endDate,
      key: "selection",
      color: "#3947A5",
    },
  ]);

  const handleClose = () => {
    dispatch(setDates({ startDate: startDate, endDate: endDate, openCalendar: false }));
  };

  return (
    <Modal
      open={openCalendar}
      onClose={() => handleClose}
      aria-labelledby='simple-modal-title'
      aria-describedby='simple-modal-description'
      closeAfterTransition
      BackdropComponent={Backdrop}
    >
      <ModalBox>
        <DateRangePicker
          ranges={dateRange}
          minDate={endOfYear(addYears(new Date(), -2))}
          maxDate={new Date()}
          onChange={(date) => {
            setDateRange([
              {
                startDate: new Date(date.selection.startDate).valueOf(),
                endDate: new Date(date.selection.endDate).valueOf(),
                key: "selection",
                color: "#3947A5",
              },
            ]);
          }}
          months={2}
          direction={"horizontal"}
          staticRanges={staticRanges}
        />
        <div
          style={{
            width: "100%",
            height: 72,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            padding: 20,
            boxShadow: "0px -2px 20px -1px rgba(0,0,0,0.1)",
          }}
        >
          <Button
            onClick={() => {
              dispatch(setDates({ startDate: startDate, endDate: endDate, openCalendar: false }));
            }}
            className={classes.cancel}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              dispatch(
                setDates({ startDate: dateRange[0].startDate, endDate: dateRange[0].endDate, openCalendar: false })
              );
            }}
            className={classes.apply}
          >
            Apply
          </Button>
        </div>
      </ModalBox>
    </Modal>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "60%",
    display: "flex",
    flexDirection: "column",
    top: 64,
    left: 270,
  },
  cancel: {
    width: 96,
    height: 32,
    backgroundColor: "rgba(0,0,0,0.1)",
    borderRadius: 5,
    fontSize: 13,
    fontFamily: "Roboto",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.1)",
    },
  },
  apply: {
    width: 96,
    height: 32,
    backgroundColor: "#3947A5",
    fontSize: 13,
    fontFamily: "Roboto",
    color: "#fff",
    textTransform: "capitalize",
    marginLeft: 20,
    "&:hover": {
      backgroundColor: "#3947A5",
    },
  },
}));

const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  startOfYear: startOfYear(new Date()),
  endOfYear: endOfYear(new Date()),
  startOfLastYear: startOfYear(addYears(new Date(), -1)),
  endOfLastYear: endOfYear(addYears(new Date(), -1)),
};

const staticRangeHandler = {
  range: {},
  isSelected(range) {
    const definedRange = this.range();
    return isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate);
  },
};

function createStaticRanges(ranges) {
  return ranges.map((range) => ({ ...staticRangeHandler, ...range }));
}

const staticRanges = createStaticRanges([
  {
    label: "Today",
    range: () => ({
      startDate: defineds.startOfToday,
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: "Yesterday",
    range: () => ({
      startDate: defineds.startOfYesterday,
      endDate: defineds.endOfYesterday,
    }),
  },

  {
    label: "This Week",
    range: () => ({
      startDate: defineds.startOfWeek,
      endDate: defineds.endOfWeek,
    }),
  },
  {
    label: "Last Week",
    range: () => ({
      startDate: defineds.startOfLastWeek,
      endDate: defineds.endOfLastWeek,
    }),
  },
  {
    label: "This Month",
    range: () => ({
      startDate: defineds.startOfMonth,
      endDate: defineds.endOfMonth,
    }),
  },
  {
    label: "Last Month",
    range: () => ({
      startDate: defineds.startOfLastMonth,
      endDate: defineds.endOfLastMonth,
    }),
  },
  {
    label: "This Year",
    range: () => ({
      startDate: defineds.startOfYear,
      endDate: defineds.endOfWeek,
    }),
  },
  {
    label: "Last Year",
    range: () => ({
      startDate: defineds.startOfLastYear,
      endDate: defineds.endOfLastYear,
    }),
  },
]);

export default React.forwardRef(CalendarModal);
