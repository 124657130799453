import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Ad } from "../model/filters/filters";

interface adLibraryState {
  selectedFilters: {
    category: string[];
    brand: string[];
    mediatype: string[];
    country: string[];
  };
  ads: Ad[];
  searchedAds: Ad[];
  totalAdsPrice: number;
  openFilters: boolean;
  invoiceID: string;
}

const initialState: adLibraryState = {
  selectedFilters: {
    category: [],
    brand: [],
    mediatype: [],
    country: [],
  },
  ads: [],
  searchedAds: [],
  totalAdsPrice: 0,
  openFilters: true,
  invoiceID: "",
};

const adLibrarySlice = createSlice({
  name: "adLibrary",
  initialState,
  reducers: {
    setFilters: (state, action: PayloadAction<any>) => {
      state.selectedFilters = action.payload;
    },
    removeFilters: (state) => {
      state.selectedFilters = {
        category: [],
        brand: [],
        mediatype: [],
        country: [],
      };
    },
    setAds: (state, action: PayloadAction<Ad[]>) => {
      state.ads = action.payload;
    },
    removeAds: (state) => {
      state.ads = [];
    },
    setSearchedAds: (state, action: PayloadAction<Ad[]>) => {
      state.searchedAds = action.payload;
    },
    setTotalAdsPrice: (state, action: PayloadAction<number>) => {
      state.totalAdsPrice = action.payload;
    },
    removeTotalAdsPrice: (state) => {
      state.totalAdsPrice = 0;
    },
    setOpenFilters: (state, action: PayloadAction<boolean>) => {
      state.openFilters = action.payload;
    },
    setInvoiceID: (state, action: PayloadAction<string>) => {
      state.invoiceID = action.payload;
    },
  },
});

export const {
  setFilters,
  removeFilters,
  setAds,
  removeAds,
  setSearchedAds,
  setTotalAdsPrice,
  removeTotalAdsPrice,
  setOpenFilters,
  setInvoiceID,
} = adLibrarySlice.actions;
export default adLibrarySlice.reducer;
