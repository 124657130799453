import React, { Suspense } from "react";
import Layout from "./components/Layout";
import { ThemeProvider, createTheme } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import Loader from "./components/loader";
import { useSelector } from "react-redux";
import { RootState } from "./store";
const App = () => {
  const theme = createTheme();
  const loading = useSelector((state: RootState) => state.loadingSlice.loading);

  return (
    <Suspense fallback={<></>}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <Layout />
          {loading && <Loader />}
        </ThemeProvider>
      </BrowserRouter>
    </Suspense>
  );
};
export default App;
