import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dates } from "../model/filters/filters";
import { startOfMonth, addMonths } from "date-fns";

const initialState: Dates = {
  startDate: startOfMonth(addMonths(new Date(), -12)),
  endDate: new Date(),
  openCalendar: false,
};

const filtersSlice = createSlice({
  name: "filtersDates",
  initialState,
  reducers: {
    setDates: (state, action: PayloadAction<Dates>) => {
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
      state.openCalendar = action.payload.openCalendar;
    },
  },
});

export const { setDates } = filtersSlice.actions;
export default filtersSlice.reducer;
