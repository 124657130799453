import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Grid,
  CssBaseline,
  IconButton,
  Typography,
  MenuItem,
  Select,
  FormControl,
  Button,
  Box,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import makeStyles from "@mui/styles/makeStyles";
import api from "../../services/config";
import API from "../../services/api_data";
import moment from "moment";
import Calendar from "../Calendar/Calendar";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { setDates } from "../../reducers/filters";
import { setOpenFilters, removeFilters, removeAds, setAds, setSearchedAds } from "../../reducers/adLibrary";

const drawerWidth = 270;

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: "flex",
  },
  periodFilter: {
    marginRight: 20,
  },
  bar: {
    [theme.breakpoints.down("md")]: {
      height: 64,
    },
  },
  toolbar: {
    paddingRight: 24,
    [theme.breakpoints.down("md")]: {
      padding: 0,
      paddingLeft: 15,
    },
  },
  toolbarIcon: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "0 8px",
  },
  appBar: {
    zIndex: "2px !important",
    backgroundColor: "#fafafa !important",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px) !important`,
    [theme.breakpoints.down("md")]: {
      marginLeft: 142,
      width: `calc(100% - ${142}px) !important`,
    },
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    fontSize: "24px !important",
    fontFamily: "Poppins !important",
    color: "#263238",
    marginRight: 35,
    textTransform: "capitalize",
  },
  select: {
    [theme.breakpoints.down("md")]: {
      "& > div:first-child": {
        padding: "0 !important",
      },
    },
  },
  selectItem: {
    fontSize: 13,
    fontFamily: "Poppins-Medium",
    color: "#263238",
    "&:hover": {
      color: "#3966D0",
    },
  },
  menuItem: {
    fontSize: 13,
    fontFamily: "Poppins-Medium",
    color: "#263238",
    "&:hover": {
      backgroundColor: "rgba(96,132,217, 0.14)!important",
    },
  },
  label: {
    fontSize: 14,
    fontFamily: "Poppins",
    color: "#263238",
    fontWeight: 500,
    lineHeight: 20,
  },
  container: {
    marginTop: 24,
    marginBottom: 24,
  },
  margin: {
    margin: 24,
  },
}));

interface Props {
  readonly setRenderAuth: Function;
  readonly open: boolean;
  readonly handleTourStart: () => void;
  readonly setOpen: Function;
}

const Demo_Url = "https://ornico.co/request-a-demo/";

export const TopBar = ({ setRenderAuth, open, handleTourStart, setOpen }: Props): JSX.Element => {
  const classes = useStyles();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [periodFilter, setPeriodFilter] = useState("Last year");
  const Ads = useSelector((state: RootState) => state.adLibrarySlice.ads);
  const startDate = useSelector((state: RootState) => state.filtersSlice.startDate);
  const endDate = useSelector((state: RootState) => state.filtersSlice.endDate);
  const openCalendar = useSelector((state: RootState) => state.filtersSlice.openCalendar);
  const openFilters = useSelector((state: RootState) => state.adLibrarySlice.openFilters);
  const userData = useSelector((state: RootState) => state.userSlice.user);

  const handleCloseUserMenu = () => {
    api.oauth.logout();
    setRenderAuth(false);
    dispatch(removeFilters());
    dispatch(removeAds());
    dispatch(setSearchedAds([]));
    navigate("/login");
  };

  useEffect(() => {
    if (!Ads.length) {
      API.getCartAds(userData.userId)
        .then((response) => {
          if (response) {
            dispatch(setAds(response));
          }
        })
        .catch((error) => {});
    }
  }, []);

  const getTabName = () => location.pathname.substring(1);

  const isShowTutorial = (route: string) => {
    if (route === "cart" || route === "ad-library" || route === "my-account" || route === "my-ads") {
      return true;
    } else {
      return false;
    }
  };

  const onContactUs = () => {
    const email = "salesstaff@ornicogroup.co.za";

    const mailtoLink = `mailto:${email}`;

    window.location.href = mailtoLink;
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      {openCalendar && <Calendar />}
      <AppBar position='absolute' className={`${classes.appBar} ${open && classes.appBarShift}`}>
        <Toolbar className={classes.toolbar}>
          {!open && (
            <IconButton
              edge='start'
              aria-label='open drawer'
              onClick={() => setOpen(true)}
              className={`${classes.menuButton} ${open && classes.menuButtonHidden}`}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Grid container className={classes.bar} direction='row' alignItems='center'>
            <Grid item sx={{ marginRight: "8px" }}>
              <Typography component='h1' variant='h6' noWrap className={classes.title}>
                {getTabName().replace("-", " ")}
              </Typography>
            </Grid>
            <Grid item sx={{ display: getTabName() === "ad-library" ? "block" : "none" }}>
              <Button onClick={() => dispatch(setOpenFilters(!openFilters))}>
                <Box display='flex' flexDirection='row' alignItems='center'>
                  {openFilters ? (
                    <>
                      <svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                          opacity='0.8'
                          d='M11.2944 21.0557C17.0161 21.0557 21.7534 16.3184 21.7534 10.5967C21.7534 4.88525 17.0059 0.137695 11.2842 0.137695C5.57275 0.137695 0.835449 4.88525 0.835449 10.5967C0.835449 16.3184 5.58301 21.0557 11.2944 21.0557ZM11.2944 19.3125C6.45459 19.3125 2.58887 15.4365 2.58887 10.5967C2.58887 5.76709 6.44434 1.88086 11.2842 1.88086C16.124 1.88086 20 5.76709 20.0103 10.5967C20.0205 15.4365 16.1343 19.3125 11.2944 19.3125ZM5.70605 8.62793H16.8931C17.3442 8.62793 17.6724 8.34082 17.6724 7.91016C17.6724 7.46924 17.3442 7.18213 16.8931 7.18213H5.70605C5.25488 7.18213 4.93701 7.46924 4.93701 7.91016C4.93701 8.34082 5.25488 8.62793 5.70605 8.62793ZM7.23389 12.022H15.3755C15.8267 12.022 16.1445 11.7349 16.1445 11.3042C16.1445 10.8633 15.8267 10.5762 15.3755 10.5762H7.23389C6.77246 10.5762 6.45459 10.8633 6.45459 11.3042C6.45459 11.7349 6.77246 12.022 7.23389 12.022ZM8.81299 15.4263H13.7861C14.2373 15.4263 14.5654 15.1392 14.5654 14.7085C14.5654 14.2676 14.2373 13.9805 13.7861 13.9805H8.81299C8.36182 13.9805 8.04395 14.2676 8.04395 14.7085C8.04395 15.1392 8.36182 15.4263 8.81299 15.4263Z'
                          fill='black'
                        />
                      </svg>
                      <Typography
                        style={{
                          fontSize: 18,
                          fontFamily: "Poppins-Medium",
                          color: "#000000",
                          textTransform: "capitalize",
                          marginLeft: 8,
                        }}
                      >
                        Filter report
                      </Typography>
                    </>
                  ) : (
                    <>
                      <svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                          opacity='0.8'
                          d='M11.2944 21.0557C17.0161 21.0557 21.7534 16.3184 21.7534 10.5967C21.7534 4.88525 17.0059 0.137695 11.2842 0.137695C5.57275 0.137695 0.835449 4.88525 0.835449 10.5967C0.835449 16.3184 5.58301 21.0557 11.2944 21.0557ZM11.2944 19.3125C6.45459 19.3125 2.58887 15.4365 2.58887 10.5967C2.58887 5.76709 6.44434 1.88086 11.2842 1.88086C16.124 1.88086 20 5.76709 20.0103 10.5967C20.0205 15.4365 16.1343 19.3125 11.2944 19.3125ZM5.70605 8.62793H16.8931C17.3442 8.62793 17.6724 8.34082 17.6724 7.91016C17.6724 7.46924 17.3442 7.18213 16.8931 7.18213H5.70605C5.25488 7.18213 4.93701 7.46924 4.93701 7.91016C4.93701 8.34082 5.25488 8.62793 5.70605 8.62793ZM7.23389 12.022H15.3755C15.8267 12.022 16.1445 11.7349 16.1445 11.3042C16.1445 10.8633 15.8267 10.5762 15.3755 10.5762H7.23389C6.77246 10.5762 6.45459 10.8633 6.45459 11.3042C6.45459 11.7349 6.77246 12.022 7.23389 12.022ZM8.81299 15.4263H13.7861C14.2373 15.4263 14.5654 15.1392 14.5654 14.7085C14.5654 14.2676 14.2373 13.9805 13.7861 13.9805H8.81299C8.36182 13.9805 8.04395 14.2676 8.04395 14.7085C8.04395 15.1392 8.36182 15.4263 8.81299 15.4263Z'
                          fill='#3966D0'
                        />
                      </svg>
                      <Typography
                        style={{
                          fontSize: 18,
                          fontFamily: "Poppins-Medium",
                          color: "#3966D0",
                          textTransform: "capitalize",
                          marginLeft: 8,
                        }}
                      >
                        Filter report
                      </Typography>
                    </>
                  )}
                </Box>
              </Button>
            </Grid>
            <Grid item sx={{ marginLeft: "6px" }}>
              <FormControl className={classes.periodFilter}>
                <Button
                  onClick={() => {
                    dispatch(setDates({ startDate: startDate, endDate: endDate, openCalendar: !openCalendar }));
                  }}
                >
                  <Typography
                    style={{
                      fontSize: 18,
                      fontFamily: "Poppins",
                      color: "#3966D0",
                      textTransform: "capitalize",
                      marginRight: 10,
                    }}
                  >
                    {periodFilter}
                  </Typography>
                  <svg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      opacity='0.8'
                      d='M5.88477 7.16211C6.19531 7.15625 6.44727 7.04492 6.69336 6.79297L11 2.38086C11.1875 2.19336 11.2754 1.97656 11.2754 1.70703C11.2754 1.16797 10.8359 0.722656 10.3027 0.722656C10.0391 0.722656 9.78711 0.833984 9.58203 1.03906L5.89062 4.86523L2.1875 1.03906C1.98828 0.839844 1.73633 0.722656 1.4668 0.722656C0.933594 0.722656 0.494141 1.16797 0.494141 1.70703C0.494141 1.9707 0.587891 2.19336 0.769531 2.38672L5.07617 6.79297C5.33398 7.04492 5.58008 7.16211 5.88477 7.16211Z'
                      fill='#3966D0'
                    />
                  </svg>
                </Button>
              </FormControl>
            </Grid>
            <Grid item>
              <Typography
                style={{
                  fontSize: 18,
                  fontFamily: "Poppins",
                  color: "#3966D0",
                }}
              >
                {moment(startDate).format("YYYY-MM-DD") + " - " + moment(endDate).format("YYYY-MM-DD")}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                onClick={handleTourStart}
                sx={{
                  display: isShowTutorial(getTabName()) ? "block" : "none",
                  marginLeft: "1rem",
                  backgroundColor: "gray",
                }}
              >
                <Typography
                  style={{
                    fontSize: 18,
                    fontFamily: "Poppins-Medium",
                    color: "#fff",
                    textTransform: "capitalize",
                    marginLeft: 4,
                    marginRight: 4,
                  }}
                >
                  Start tutorial
                </Typography>
              </Button>
            </Grid>
            <Grid item style={{ flex: 1 }}>
              <Grid container justifyContent='flex-end'>
                <Grid item sx={{ display: "flex", alignItems: "center" }}>
                  <Button onClick={() => window.open(Demo_Url)} sx={{ backgroundColor: "#3cb371" }}>
                    <Typography
                      style={{
                        fontSize: 18,
                        fontFamily: "Poppins-Medium",
                        color: "#fff",
                        textTransform: "capitalize",
                        marginLeft: 4,
                        marginRight: 4,
                      }}
                    >
                      Get Pythia
                    </Typography>
                  </Button>
                </Grid>
                <Grid item>
                  <Select
                    value={0}
                    className={classes.select}
                    sx={{ position: "unset !important" }}
                    inputProps={{
                      "aria-label": "Without label",
                    }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                    }}
                    IconComponent={() => (
                      <svg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                          d='M1.41 -7.62939e-08L6 4.59L10.59 -7.62939e-08L12 1.42L6 7.42L0 1.42L1.41 -7.62939e-08Z'
                          fill='#909597'
                        />
                      </svg>
                    )}
                  >
                    <MenuItem hidden className={classes.menuItem} value={0}>
                      <div className={classes.selectItem} onClick={() => {}}>
                        {userData.firstName} {userData.lastName}
                      </div>
                    </MenuItem>
                    <MenuItem>
                      <div className={classes.selectItem} onClick={onContactUs}>
                        Contact Us
                      </div>
                    </MenuItem>
                    <MenuItem className={classes.menuItem} value={1} onClick={handleCloseUserMenu}>
                      <div className={classes.selectItem} style={{ color: "#D26767" }}>
                        Logout
                      </div>
                    </MenuItem>
                  </Select>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
};
