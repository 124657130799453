import adLibrarySlice from "./adLibrary";
import userSlice from "./user";
import filtersSlice from "./filters";
import loadingSlice from "./loading";

export const rootReducer = {
  adLibrarySlice,
  userSlice,
  filtersSlice,
  loadingSlice,
};
