import api from "./config";

var apiData = {};

apiData.getfiltereddata = (filters, pageNumber = 1, pageSize = 100) => {
  return new Promise((resolve, reject) => {
    api
      .post(`/AdShop/getfiltereddata?pageNumber=${pageNumber}&pageSize=${pageSize}`, filters)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

apiData.getMyAds = (filters, pageNumber = 1, pageSize = 100) => {
  return new Promise((resolve, reject) => {
    api
      .post(`/AdShop/myads?pageNumber=${pageNumber}&pageSize=${pageSize}`, filters)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

apiData.saveCartAds = (data) => {
  return new Promise((resolve, reject) => {
    api
      .post(`/AdShop/savecart`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

apiData.getCartAds = (userID) => {
  return new Promise((resolve, reject) => {
    api
      .get(`/AdShop/getcart?UserId=${userID}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

apiData.downloadInvoiceMedia = (invoiceID) => {
  return new Promise((resolve, reject) => {
    api
      .post(`/AdShop/downloadinvoicemedia?invoiceID=${invoiceID}`, {}, "blob")
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

apiData.downloadMultipleMediaFiles = (data) => {
  return new Promise((resolve, reject) => {
    api
      .post(`/AdShop/downloadmultiplemediafiles`, data, "blob")
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

apiData.applyPayment = (paymentData) => {
  return new Promise((resolve, reject) => {
    api
      .post(`/AdShop/payment`, paymentData)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

apiData.applyPromoCode = (promoCodeData) => {
  return new Promise((resolve, reject) => {
    api
      .post(`/AdShop/validatecoupon`, promoCodeData)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

apiData.getuserInvoices = (filters, pageNumber = 1, pageSize = 10) => {
  return new Promise((resolve, reject) => {
    api
      .post(`/AdShop/userinvoices?pageNumber=${pageNumber}&pageSize=${pageSize}`, filters)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

apiData.getuserSearchHistories = (filters, pageNumber = 1, pageSize = 10) => {
  return new Promise((resolve, reject) => {
    api
      .post(`/AdShop/usersearchhistories?pageNumber=${pageNumber}&pageSize=${pageSize}`, filters)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

apiData.inviteUser = (invitationData) => {
  return new Promise((resolve, reject) => {
    api
      .post(`/AdShop/inviteuser`, invitationData)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

apiData.upgradePlan = (upgradeDetails) => {
  return new Promise((resolve, reject) => {
    api
      .post(`/AdShop/upgradeplan`, upgradeDetails)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

apiData.getBrands = (searchtext) => {
  return new Promise((resolve, reject) => {
    api
      .get(searchtext ? `/shared/brands/${searchtext}` : "/shared/brands")
      .then((response) => {
        // console.log('RESPONSE BRANDS: ', response.data)
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

apiData.getCategories = (searchtext) => {
  return new Promise((resolve, reject) => {
    api
      .get(searchtext ? `/shared/categories/${searchtext}` : "/shared/categories")
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

apiData.getMediaTypes = () => {
  return new Promise((resolve, reject) => {
    api
      .get("/shared/mediatypes")
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

apiData.getSubCategories = (searchtext) => {
  return new Promise((resolve, reject) => {
    api
      .get(searchtext ? `/shared/subcategories/${searchtext}` : "/shared/subcategories")
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

apiData.getCountries = (searchtext) => {
  return new Promise((resolve, reject) => {
    api
      .get(searchtext ? `/shared/countries/${searchtext}` : "/shared/countries")
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

apiData.getExport = (filters) => {
  return new Promise((resolve, reject) => {
    api
      .post(`/analytics/exportdata`, filters)
      .then((response) => {
        // console.log('RESPONSE ANALYTICS: ', response.data)
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

apiData.getExcelExport = (definition) => {
  return new Promise((resolve, reject) => {
    api
      .post(`/analytics/exporttoexcel`, definition, "blob")
      .then((response) => {
        // console.log('RESPONSE ANALYTICS: ', response.data)
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

apiData.refreshToken = (data) => {
  return new Promise((resolve, reject) => {
    api
      .post("/Users/refresh_token", data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

apiData.updateUser = (userDetails) => {
  return new Promise((resolve, reject) => {
    api
      .put(`/users/${userDetails.userId}`, userDetails)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

apiData.createUser = (userDetails) => {
  return new Promise((resolve, reject) => {
    api
      .post("/users", userDetails)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default apiData;
