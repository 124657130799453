import axios from "axios";

var api = {};
var apiAuth = {};
// var apiUrl = process.env.REACT_APP_APIURL; //sorry Derrick, this doesn't work on https://ornico.touch-media.ro/
var apiUrl = "https://fusion2.ornico.co.za"; //prod
//var apiUrl = "https://stageapi.ornico.co.za"; //dev
//var apiUrl = "http://localhost:3000";

var apiKey = "c0564422f08d91a62f0d1019ca73c0a2";

api.url = apiUrl;
api.baseUrl = apiUrl;
api.oauth = {};

apiAuth = {
  user: null,
  token: {
    token_type: "Bearer",
    access_token: null,
    expires_at: null,
  },
};

api.post = (url, data, responsetype = "") => {
  return api.request("POST", url, data, responsetype);
};

api.put = (url, data) => {
  return api.request("PUT", url, data);
};

api.get = (url, data) => {
  return api.request("GET", url, data);
};
api.delete = (url, data) => {
  return api.request("DELETE", url, data);
};
api.request = (method, url, data, responsetype) => {
  return new Promise((resolve, reject) => {
    method = method.toUpperCase();
    url = apiUrl + url;
    let headers = {};
    headers = api.oauth.authorizationHeader(apiAuth);
    headers["Content-Type"] = "application/json";
    headers["x-api-key"] = apiKey;

    api
      .sendRequest(method, url, headers, data, responsetype)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          api.oauth.logout();
        }
        reject(error);
      });
  });
};

api.sendRequest = (method, url, headers, data, responsetype = "") => {
  return new Promise((resolve, reject) => {
    let options = {};

    if (responsetype === "blob") {
      options = {
        method: method,
        url: url,
        headers: headers,
        responseType: "blob",
      };
    } else {
      options = {
        method: method,
        url: url,
        headers: headers,
      };
    }

    if (method === "GET") options.params = data;
    else options.data = data;

    axios(options)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          api.oauth.logout();
        }
        reject(error);
      });
  });
};

api.oauth.check = () => {
  return new Promise((resolve, reject) => {
    let authString = sessionStorage.getItem("auth");
    let auth = JSON.parse(authString);
    apiAuth = auth;
    resolve(auth);
  });
};

api.oauth.authorizationHeader = (apiAuth) => {
  if (!apiAuth?.user?.accessToken) return {};
  return { Authorization: "Bearer " + apiAuth?.user?.accessToken };
};

api.oauth.verifyIfTokenExpired = (token) => {
  let time_now = new Date().getTime();
  let token_expiration = token.expires_at || 0;
  return time_now >= token_expiration;
};

api.oauth.processNewToken = (token) => {
  const token_expires_in = 150000;
  token.issued_at = new Date().getTime();
  token.expires_at = token.issued_at + token_expires_in;
  return token;
};

api.oauth.updateAuth = (auth) => {
  apiAuth = auth;
  localStorage.setItem("auth", JSON.stringify(auth));
};

api.oauth.logout = () => {
  localStorage.clear();
  return <></>;
};

api.oauth.getAuth = () => {
  return apiAuth;
};

export default api;
