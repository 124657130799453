import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserData } from "../model/user/user";

const initialState: UserData = {
  token: {
    access_token: "",
    expires_at: 0,
    issued_at: 0,
  },
  user: {
    companyName: "",
    book: false,
    enabled: false,
    firstName: "",
    email: "",
    userId: 1,
    internal: false,
    isPasswordChangeRequired: false,
    lastName: "",
    role: "",
    vatNumber: "",
    phoneNumber: "",
    accessToken: "",
    refreshToken: "",
    userName: "",
  },
};

const userSlice = createSlice({
  name: "userData",
  initialState,
  reducers: {
    setUserData: (state, action: PayloadAction<UserData>) => {
      state.token = action.payload.token;
      state.user = action.payload.user;
    },
    removeUserData: (state) => {
      state = {
        token: {
          access_token: "",
          expires_at: 0,
          issued_at: 0,
        },
        user: {
          companyName: "",
          book: false,
          enabled: false,
          firstName: "",
          email: "",
          userId: 1,
          internal: false,
          isPasswordChangeRequired: false,
          lastName: "",
          role: "",
          vatNumber: "",
          phoneNumber: "",
          accessToken: "",
          refreshToken: "",
          userName: "",
        },
      };
    },
  },
});

export const { setUserData, removeUserData } = userSlice.actions;
export default userSlice.reducer;
